import React, { Component } from "react";
import { render } from "react-dom";

import { init, locations } from "@contentful/app-sdk";
import "@contentful/forma-36-react-components/dist/styles.css";
import "@contentful/forma-36-fcss/dist/styles.css";
import { Heading, Note, Form, Textarea, FormLabel } from "@contentful/forma-36-react-components";

class Config extends Component {
  constructor(props) {
    super(props);
    this.state = {
      parameters: {
        contentTypeIds: JSON.stringify(['contentTypeId', 'contentTypeId1'], null, "\t"), 
        teamTags: JSON.stringify([ { teamId: 'teamAId', tags: ['tagA', 'tagAA'] }, { teamId: 'teamBId', tags: ['tagB', 'tagBB'] } ], null, "\t")
      } 
    };
    this.app = this.props.sdk.app;
    this.app.onConfigure(() => this.onConfigure());
  }

  handleChange(event) {
    let parameters = this.state.parameters;
    let value = "";
    if (event.target.value) {
      let parsed = JSON.parse(event.target.value);
      value = JSON.stringify(parsed, null, "\t");
    }
    parameters[event.target.name] = value
    this.setState({
      parameters,
    });
  }

  async componentDidMount() {
    const parameters = await this.app.getParameters();
    this.setState({ parameters: parameters || { contentTypeIds: JSON.stringify(['contentTypeId', 'contentTypeId1'], null, "\t"), teamTags: JSON.stringify([ { teamId: 'teamAId', tags: ['tagA', 'tagAA'] }, { teamId: 'teamBId', tags: ['tagB', 'tagBB'] } ], null, "\t") } }, () =>
      this.app.setReady()
    );
  }

  render() {
    return (
      <Form id="app-config">
        <Heading>Auto Tagging App</Heading>
        <Note noteType="primary" title="About the app">
          In the first input field provide the Content Types where the event should be executed. <strong>Leave it empty to include all Content Types.</strong>
          <br/>
          In the second input field provide the Team/Tags mapping.
        </Note>
        <FormLabel htmlFor="contentTypeIds">Set ContentTypeIds as JSON Array</FormLabel>
        <Textarea
          name="contentTypeIds"
          value={this.state.parameters.contentTypeIds}
          rows={4}
          width="large"
          onChange={e => this.handleChange(e)}
        ></Textarea>
        <FormLabel htmlFor="teamTags">Set Tags as JSON Array</FormLabel>
        <Textarea
          name="teamTags"
          value={this.state.parameters.teamTags}
          width="large"
          rows={16}
          onChange={e => this.handleChange(e)}
        ></Textarea>
      </Form>
    );
  }

  async onConfigure() {
    return {
      parameters: this.state.parameters
    };
  }
}

init(sdk => {
  const root = document.getElementById("root");
  if (sdk.location.is(locations.LOCATION_APP_CONFIG)) {
    render(<Config sdk={sdk} />, root);
  } else {
    throw new Error("rendered outside of config location");
  }
});
